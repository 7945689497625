<template>
  <div>Ceci est bien la page de test</div>
</template>

<script>
export default {
  metaInfo: {},
};
</script>

<style lang="scss" scoped></style>
